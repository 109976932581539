import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { Trans } from 'react-i18next'
import styled from 'styled-components'
import Layout from '../../../layouts/base'

const Content = styled.div`
  max-width: 860px;
  margin: 0 auto;
  padding: 0px 20px 40px 20px;
  .fiddle {
    height: 425px;
    margin-bottom: 30px;
    iframe {
      box-shadow: 0 0 8px rgba(0,0,0,.3);
    }
  }
  h1, h2, h3, h4, h5 {
    color: ${props => props.theme.accent};
    display: inline-block;
  }
  h2 {
    margin-top: 25px;
  }
`

const Articles = styled.div`

`

const Article = styled.div``

interface IProps {
  data: {
    markdownRemark: {
      id: string
      frontmatter: {
        title: string
        cover: {
          childImageSharp: any
        }
      }
      html: string
    }
  }
}

const ArticlePage = (props: IProps) => {
  const [codepen, setCodepen] = useState(false)

  useEffect(() => {
    const codepen = document.getElementsByClassName('codepen')
    if (codepen.length > 0) {
      if (!document.getElementById('codepen-script') || !codepen) {
        const s = document.createElement('script')
        s.async = s.defer = true
        s.src = `//static.codepen.io/assets/embed/ei.js`
        s.id = 'codepen-script'
        const body = document.body
        if (body) {
          body.appendChild(s)
        }
        setCodepen(true)
      }
    }

    Array.from(document.getElementsByClassName('fiddle')).map(a => {
      if (a.children.length > 0) { return }
      const s: HTMLScriptElement = document.createElement('script')
      s.async = s.defer = true
      s.src = a.getAttribute('src') || ''
      a.appendChild(s)
    })
  }, [])

  if (!props.data) return <div>Error.</div>
  return <Layout>
    <Content>
      <h1><Trans>{props.data.markdownRemark.frontmatter.title}</Trans></h1>
      <section dangerouslySetInnerHTML={{ __html: props.data.markdownRemark.html }} />
    </Content>
  </Layout>
}

export const query = graphql`
  query getArticle($id: String!) {
    # markdownRemark(frontmatter:{slug:{eq:$slug}}) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
      }
      html
      html
    }
  }
`;


export default ArticlePage
